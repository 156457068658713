// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-customer-success-book-js": () => import("./../../../src/pages/customer-success-book.js" /* webpackChunkName: "component---src-pages-customer-success-book-js" */),
  "component---src-pages-demo-page-js": () => import("./../../../src/pages/demo-page.js" /* webpackChunkName: "component---src-pages-demo-page-js" */),
  "component---src-pages-detailed-policies-[slug]-js": () => import("./../../../src/pages/detailedPolicies/[slug].js" /* webpackChunkName: "component---src-pages-detailed-policies-[slug]-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-pryzm-aiiiiiiii-js": () => import("./../../../src/pages/pryzmAIIIIIIII.js" /* webpackChunkName: "component---src-pages-pryzm-aiiiiiiii-js" */),
  "component---src-pages-resources-blogs-[slug]-js": () => import("./../../../src/pages/resources/blogs/[slug].js" /* webpackChunkName: "component---src-pages-resources-blogs-[slug]-js" */),
  "component---src-pages-resources-blogs-js": () => import("./../../../src/pages/resources/blogs.js" /* webpackChunkName: "component---src-pages-resources-blogs-js" */),
  "component---src-pages-resources-data-sheets-js": () => import("./../../../src/pages/resources/data-sheets.js" /* webpackChunkName: "component---src-pages-resources-data-sheets-js" */),
  "component---src-pages-resources-detailed-blog-[slug]-js": () => import("./../../../src/pages/resources/detailedBlog/[slug].js" /* webpackChunkName: "component---src-pages-resources-detailed-blog-[slug]-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-latest-js": () => import("./../../../src/pages/resources/latest.js" /* webpackChunkName: "component---src-pages-resources-latest-js" */),
  "component---src-pages-resources-videos-js": () => import("./../../../src/pages/resources/videos.js" /* webpackChunkName: "component---src-pages-resources-videos-js" */),
  "component---src-pages-resources-webinars-js": () => import("./../../../src/pages/resources/webinars.js" /* webpackChunkName: "component---src-pages-resources-webinars-js" */),
  "component---src-pages-success-page-js": () => import("./../../../src/pages/success-page.js" /* webpackChunkName: "component---src-pages-success-page-js" */),
  "component---src-pages-videos-[slug]-js": () => import("./../../../src/pages/Videos/[slug].js" /* webpackChunkName: "component---src-pages-videos-[slug]-js" */)
}

